<template>
    <!-- 摄影师优秀推荐组件 -->
    <div class="member-excellent-box">
        <div class="container">
            <div class="member-excellent-title">{{excellentTitle}}</div>
            <swiper :options="swiperOption">
                <swiper-slide
                    v-for="item in sublist"
                    :key="item.id">
                    <router-link 
                        :to="{
                            name: routerName,
                            query:{id: item.id}
                        }">
                        <el-card
                            :body-style="{ padding: '0px' }"
                            shadow="hover">
                            <member-list
                                :item-data="item">
                            </member-list>
                        </el-card>
                    </router-link>
                </swiper-slide>
                <template v-slot:button-prev>
                    <div class="swiper-button-prev">
                    </div>
                </template>
                <template v-slot:button-next>
                    <div class="swiper-button-next">
                    </div>
                </template>
            </swiper>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    data() {
        return {
            swiperOption: {
                slidesPerView: 4,
                spaceBetween: 20,
                slidesPerGroup: 4,
                loop: false,
                loopFillGroupWithBlank: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            },
            top: "",
            left: "",
            list_left: 0,
            box_width: 0
        }
    },
    props:[ "routerName", "excelTitle", "excellentTitle" ],
    computed: mapState({
        sublist: state => state.goodsList.sublist,
        list_width(){
            if(this.sublist && this.sublist.length){
                return this.sublist.length*290;
            }else{
                return 0
            }
        }
    }),
    components:{
        memberList: () => import('@/components/memberList')
    },
}
</script>

<style scoped>
.member-excellent-box{
    padding: 50px 0;
}
.member-excellent-title{
    margin-bottom: 30px;
    font-size: 25px;
}
.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled{
    opacity: 0;
}
</style>
